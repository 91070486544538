/** Available gap sizes. Add to these from the available TW sizes as needed. */
type Gap = 0 | 0.5 | 1 | 2 | 2.5 | 3 | 4 | 5 | 6 | 7 | 8 | 10 | 11 | 12 | 14;

/**
 * Return the Tailwind gap class for the given size
 *
 * NOTE: the classes (including responsive variants, if needed) must be safelisted in TW
 */
function gapClassResolver(size: Gap) {
  return `gap-${size}`;
}

export type { Gap };
export { gapClassResolver };
