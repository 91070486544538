import { StyledLink } from 'turnip/StyledLink/StyledLink';
import { supportUrl } from 'utils/carrotUrls';

/**
 * Convenience wrapper around StyledLink's contextual variant that links to the
 * support page.
 */
function SupportLink({
  children = 'contact support',
}: {
  children?: React.ReactNode;
}) {
  return (
    <StyledLink
      variant="contextual"
      to={supportUrl}
      target="_blank"
      rel="noreferrer"
      display="inline"
    >
      {children}
    </StyledLink>
  );
}

export { SupportLink };
