import clsx from 'clsx';
import { StyledLinkProps } from './StyledLink';

export const styledLinkClasses = ({
  variant = 'strong',
  display = 'inline',
  size,
}: StyledLinkProps) =>
  clsx(
    `${display} items-center rounded-sm focus-visible:outline-none focus-visible:ring-2`,
    variant !== 'contextual' &&
      'text-indigo-700 hover:text-indigo-800 focus-visible:ring-blue-500',
    variant === 'strong' && 'font-bold',
    variant === 'underlined' && 'underline',
    variant === 'plain' && 'hover:underline',
    variant === 'contextual' &&
      'font-bold text-inherit underline focus-visible:ring-current',
    size
  );
