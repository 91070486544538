import { Link, LinkProps } from 'turnip/Link/Link';
import { styledLinkClasses } from './styledLinkClasses';

/**
 * Render a styled link.
 *
 * Uses an anchor for external links; otherwise a client-side routing component.
 */
type StyledLinkVariant = 'strong' | 'underlined' | 'plain' | 'contextual';
type StyledLinkProps = {
  /** Visual variant to display */
  variant?: StyledLinkVariant;
  /** Tailwind CSS text size class */
  size?: `text-${string}`;
  /** Tailwind CSS display class */
  display?: string;
};
function StyledLink({
  children,
  className,
  variant,
  display = 'inline',
  size,
  ...props
}: LinkProps & StyledLinkProps) {
  return (
    <Link className={styledLinkClasses({ variant, size, display })} {...props}>
      {children}
    </Link>
  );
}

export { StyledLink };
export type { StyledLinkProps };
