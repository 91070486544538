import { graphql } from 'generated/gql/account-manager';

export const currentUserQueryDocument = graphql(`
  query currentUser($wordpressUserId: Int!) {
    user(wordpressUserId: $wordpressUserId) {
      id
      activeAccount {
        id
        owner {
          name
          carrotAccountStatus
          carrotCrmConfig {
            id
          }
        }
      }
      accounts {
        id
        name
      }
      carrotAccountStatus
      name
      email
      createdAt
      wordpressUserId
      wordpressUsername
      intercomUserHash
      superAdmin
      vip
      primaryIndustry {
        id
        name
      }
      primaryIndustryFocus {
        id
        name
      }
      currentSubscription {
        status
        canceledAt
        pastDueDate
        pastDueCancelAtDate
        pendingCancellation
        discount {
          endDate
          startDate
          coupon {
            name
            duration
            percentOff
            amountOff
          }
        }
        items {
          quantity
          price {
            id
            active
            customerType
            interval
            wordpressLabel
            version
            product {
              name
              starter
            }
          }
        }
      }
      carrotCrmConfig {
        id
      }
    }
  }
`);
